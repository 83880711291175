import React, { useContext } from "react"
import Layout from "../../components/layout"
import styles from "./item.module.scss"
import { graphql } from "gatsby"
import ThemeContext from "../../context/ThemeContext"
import SEO from "../../components/seo"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import MainSearch from "../../components/main-search/main-search"
import h from "../../services/GenericHelpers"
import localize from "../../components/localize"

const Content = ({ bonuses, locale }) => {
  return (
    <div className="cards-injected">
      <MainSearch locale={locale} bonuses={bonuses} />
    </div>
  )
}

const AllReviews = ({ location, data, pageContext }) => {
  let { locale, alternateLocaleSlugs } = pageContext

  let site = useContext(ThemeContext)
  let bonuses = site.bonuses
  let orderedBonuses = bonuses.slice()
    .sort((a, b) => {
      if (h.casinoAverageRating(b) > h.casinoAverageRating(a))
        return 1;
      if (h.casinoAverageRating(b) < h.casinoAverageRating(a))
        return -1;

      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })

  let pageData = data.sanityGenericLandingPage

  if (!pageData) pageData = {}

  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} bonuses={orderedBonuses}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
         alternateSlug={alternateLocaleSlugs}
      />
      <GenericLandingContentWrapper
        pageData={pageData}
        bodyClassName={`gambleGeneralWrapper ${styles.reviewInfoPage}`}
        headerClassName={`gambleGeneralWrapper`}
      >
        <Content bonuses={orderedBonuses} locale={locale} />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(AllReviews)
export const pageQuery = graphql`
  query CasinoReviewLanding {    
    sanitySilo (_id: {eq: "bc718067-0d15-409b-88e8-5f931a664b61"}){
      _id
      breadcrumbTrail {
          ...LocaleSiloTrails
      }
    }
    sanityGenericLandingPage(
      name: { eq: "Casino Reviews" }
    ) {
      ...GenericLanding
    }
  }
`
